<template>
    <el-main>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="会员名称：">
                <el-input v-model="searchForm.name" size="small" placeholder="请输入会员昵称"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
                <el-input v-model="searchForm.mobile" size="small"></el-input>
            </el-form-item>
            <el-form-item label="注册时间：">
                <el-date-picker v-model="searchForm.create_time" size="small" type="daterange" value-format="timestamp"
                    range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button size="small" @click="search" type="primary">搜索</el-button>
                <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="nickname" label="店铺名称" width="200" align="center">
                <template v-slot="{ row }">
                    <userInfo :name="row.shop_name" :avatar="row.shop_logo"></userInfo>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="会员姓名" width="200" align="center"></el-table-column>
            <el-table-column prop="mobile" label="联系电话" width="200" align="center"></el-table-column>
            <el-table-column prop="address" label="地址" width="200" align="center"></el-table-column>
            <el-table-column prop="goods_num" label="商品数" width="150" align="center"></el-table-column>
            <el-table-column prop="service_num" label="服务数" width="150" align="center"></el-table-column>
            <el-table-column prop="goods_order_count" label="商品订单数" width="150" align="center"></el-table-column>
            <el-table-column prop="service_order_count" label="服务订单数" width="150" align="center"></el-table-column>
            <el-table-column prop="goods_sales_amount" label="营业额" width="150" align="center">
                <template v-slot="{ row }">￥{{ row.goods_sales_amount + row.service_sales_amount }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="260">
                <template v-slot="{ row }">
                    <el-popover placement="top-start" width="200" trigger="click">
                        <div>账号：{{ row.account_info.account }}</div>
                        <div>密码：{{ row.account_info.passwords }}</div>
                        <el-button type="text" size="small" slot="reference">账号密码</el-button>
                    </el-popover>
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            店铺管理
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <span @click="go(1, row)">实物商品</span>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <span @click="go(2, row)">服务商品</span>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <span @click="go(3, row)">代销合同</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button @click="goStore" type="text" size="small">跳转门店</el-button>
                    <el-button @click="del(row.id)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
    </el-main>
</template>

<script>
import userInfo from '@/components/userInfo';
import Paging from '@/components/paging';
import _ from 'lodash';
import config from '@/util/config';
export default {
    components: {
        userInfo,
        Paging,
    },
    data () {
        return {
            searchForm: {
                page: 1,
                rows: 10,
                name: '',
                mobile: '',
                create_time: [],
            },
            storeurl: config.storeurl,
            list: [],
            total_number: 0,
        };
    },
    created () {
        this.getList();
    },
    methods: {
        goStore () {
            window.open(this.storeurl);
        },
        go (type, row) {
            if (type == 1) {
                this.$router.push({
                    path: '/shopManagement/gatewayGoodsManagement',
                    query: {
                        id: row.id,
                        shop_logo: row.shop_logo,
                        shop_name: row.shop_name,
                    },
                });
            }
            if (type == 2) {
                this.$router.push({
                    path: '/shopManagement/gatewayServiceManagement',
                    query: {
                        id: row.id,
                        shop_logo: row.shop_logo,
                        shop_name: row.shop_name,
                    },
                });
            }
            if (type == 3) {
                this.$router.push({
                    path: '/shopManagement/gatewayProxy',
                    query: {
                        shop_logo: row.shop_logo,
                        shop_name: row.shop_name,
                    },
                });
            }
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        searchClear () {
            this.searchForm = {
                page: 1,
                rows: 10,
                name: '',
                mobile: '',
                create_time: [],
            };
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
            } else {
                this.searchForm.page = val;
            }
            this.getList();
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            for (let i in searchForm.create_time) {
                searchForm.create_time[i] = searchForm.create_time[i] / 1000;
            }
            this.$axios
                .post(this.$api.gateway.member.list, {
                    is_shop: 1,
                    ...searchForm,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.list = res.result.list;
                        this.total_number = res.result.total_number;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        del (id) {
            this.$confirm('是否删除该店铺?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$api.gateway.member.del, { id })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            }).catch(() => { });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
        margin-left: 10px;
        margin-right: 10px;
    }

    .el-icon-arrow-down {
        font-size: 12px;
    }
}
</style>
